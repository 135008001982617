import {
  ProfileSettingsPT,
  ProfileSettingsEN,
  ProfileSettingsES,
} from '@containers/ProfileSettings/ProfileSettingsTranslator';
import {
  BreadCrumbPT,
  BreadCrumbEN,
  BreadCrumbES,
} from 'components/BreadCrumb/locale/BreadCrumbTranslator';
import { HeaderPT, HeaderEN, HeaderES } from 'containers/Header/HeaderTranslator';
import { DashboardPT, DashboardEN, DashboardES } from '@containers/Dashboard/DashboardTranslator';
import {
  MenuProfilePT,
  MenuProfileEN,
  MenuProfileES,
} from '@containers/Menus/MenuProfile/MenuProfileTranslator';
import {
  MenuProfileMobilePT,
  MenuProfileMobileEN,
  MenuProfileMobileES,
} from 'containers/Menus/MenuProfileMobile/MenuProfileMobileTranslator';
import {
  MenuNotificationPT,
  MenuNotificationEN,
  MenuNotificationES,
} from 'containers/Menus/MenuNotification/MenuNotificationTranslator';
import {
  SettingsEasyAccessEN,
  SettingsEasyAccessPT,
} from 'containers/Settings/EasyAccess/EasyAccessTranslator';

import {
  ImageDropZonePT,
  ImageDropZoneEN,
  ImageDropZoneES,
} from '@containers/Scope/ProfileAndCover/ModalChangeImages/ImageDropZone/ImageDropZoneTranslator';
import { DateRangePT, DateRangeEN, DateRangeES } from 'components/DateRange/DateRangeTranslator';
import {
  ButtonCalendarPT,
  ButtonCalendarEN,
  ButtonCalendarES,
} from '@containers/Tabs/ButtonCalendar/ButtonCalendarTranslator';
import {
  CardLoginEN,
  CardLoginPT,
  CardLoginES,
} from '@containers/Login/CardLogin/CardLoginTranslator';
import {
  ChooseCompanyEN,
  ChooseCompanyPT,
} from '@containers/Login/ChooseCompany/ChooseCompanyTranslator';
import { GuestEN, GuestPT } from '@containers/Login/Guest/GuestTranslator';
import {
  CreatePasswordEN,
  CreatePasswordPT,
} from '@containers/Login/CreatePassword/CreatePasswordTranslator';
import { MainMenuPT, MainMenuEN } from '@containers/MainMenu/MainMenuTranslator';
import { SidebarPT, SidebarEN, SidebarES } from '@containers/Sidebar/SidebarTranslator';
import { TabsPT, TabsEN, TabsES } from '@containers/Tabs/TabsTranslator';
import {
  ListAllPT,
  ListAllEN,
  ListAllES,
} from '@containers/Scope/Report/ListAll/ListAllTranslator';
import { TablePT, TableEN, TableES } from '@containers/Scope/Report/ListAll/Table/TableTranslator';
import {
  TableBudgetAccountsPT,
  TableBudgetAccountsEN,
  TableBudgetAccountsES,
} from '@containers/Scope/Budget/Overall/TableBudgetAccounts/TableBudgetAccountsTranslator';
import {
  OverallPT,
  OverallEN,
  OverallES,
} from '@containers/Scope/Budget/Overall/OverallTranslator';
import {
  OverviewPT,
  OverviewEN,
  OverviewES,
} from '@containers/Scope/Report/Overview/OverviewTranslator';
import {
  RankingPT,
  RankingEN,
  RankingES,
} from '@containers/Scope/Report/Ranking/RankingTranslator';
import {
  AudiencePT,
  AudienceEN,
  AudienceES,
} from '@containers/Scope/Report/Audience/AudienceTranslator';
import {
  EngagementPT,
  EngagementEN,
  EngagementES,
} from '@containers/Scope/Report/Engagement/TopEngagement/Engagement/EngagementTranslator';
import {
  VideoViewPT,
  VideoViewEN,
  VideoViewES,
} from '@containers/Scope/Report/VideoView/VideoViewTranslator';
import {
  CommentsPT,
  CommentsEN,
  CommentsES,
} from '@containers/Scope/Report/Comments/CommentsTranslator';
import {
  ActivityPT,
  ActivityEN,
  ActivityES,
} from '@containers/Scope/Report/Activity/ActivityTranslator';
import { MediasPT, MediasEN, MediasES } from '@containers/Scope/Medias/MediasTranslator';
import { FeedPT, FeedEN, FeedES } from '@containers/Scope/MediasCenter/pages/Feed/translate';
import {
  ContentActionsPT,
  ContentActionsEN,
  ContentActionsES,
} from '../containers/Scope/MediasCenter/pages/Approvals/translate';
import { MetricsPanelPT, MetricsPanelEN, MetricsPanelES } from './MetricsPanel';
import {
  CardCampaignEN,
  CardCampaignPT,
  CardCampaignES,
} from '@components/CardCampaignInfo/cardCampaignTranslator';
import {
  ManageGroupsPT,
  ManageGroupsEN,
  ManageGroupsES,
} from '@containers/Scope/Report/ListAll/Table/Rows/ManageGroups/ManageGroupsTranslator';

import { ErrorPagePT, ErrorPageEN, ErrorPageES } from 'containers/ErrorPage/ErrorPageTranslator';
import { UserPT, UserEN, UserES } from '../containers/Users/UsersTranslator';
import { UnitsPT, UnitsEN, UnitsES } from '../containers/Settings/Units/UnitsTranslator';
import { OnboardingEN, OnboardingPT } from '../containers/Settings/Onboarding/OnboardingTranslator';
import {
  UserTablePT,
  UserTableES,
  UserTableEN,
} from '../containers/Settings/UserTable/UserTableTranslator';
import { GroupsPT, GroupsEN } from '../containers/Settings/Groups/GroupsTranslator';
import { CompanyEN, CompanyES, CompanyPT } from '../containers/Settings/Company/CompanyTranslator';

import {
  NewDashboardEN,
  NewDashboardES,
  NewDashboardPT,
} from '../containers/Scope/Report/Dashboard/DashboardTranslator';
import {
  OrganicPostsEN,
  OrganicPostsES,
  OrganicPostsPT,
} from '../containers/Scope/Report/Overview/Global/OrganicPostsCard/OrganicPostsTranslator';
import {
  PaidPerformanceEN,
  PaidPerformanceES,
  PaidPerformancePT,
} from '../containers/Scope/Report/Dashboard/PaidPerformanceCard/PaidPerformanceTranlsator';
import {
  FollowUpEN,
  FollowUpES,
  FollowUpPT,
} from '../containers/Scope/Report/Dashboard/FollowUpCard/FollowUpTranslator';
import {
  HeatMapEN,
  HeatMapES,
  HeatMapPT,
} from '../containers/Scope/Report/Dashboard/HeatMap/utils/translate';
import {
  HomeBudgetEN,
  HomeBudgetES,
  HomeBudgetPT,
} from '../containers/Scope/Report/Dashboard/BudgetCard/BudgetTranslator';

import { CustomEN, CustomPT, CustomES } from '../containers/Scope/Report/Custom/CustomTranslator';
import { WeekReportEN, WeekReportPT } from '../containers/Settings/WeekReport/WeekReportTranslator';
import { MetricsEN, MetricsPT } from '../components/MetricsModal/translator';

import {
  ModalMetricsTableEN,
  ModalMetricsTablePT,
} from '../components/ModalMetricsTable/translator';

import { LineChartsEN, LineChartsPT, LineChartsES } from '../components/LineChats/translator';

import {
  TableMetricsCustomEN,
  TableMetricsCustomPT,
  TableMetricsCustomES,
} from '../components/TableMetricsCustom/translator';

import { MixedChartsEN, MixedChartsPT } from '../components/MixedCharts/translator';
import { MainMenuES } from '../containers/MainMenu/MainMenuTranslator';

import { GDScoreEN, GDScorePT, GDScoreES } from '../containers/GDScore/GDScoreTranslator';

import {
  HelpCenterEN,
  HelpCenterPT,
  HelpCenterES,
} from '../containers/HelpCenter/HelpCenterTranslator';

import {
  CampaignStepsEN,
  CampaignStepsPT,
  CampaignStepsES,
} from '@containers/Scope/Medias/Main/Campaigns/Form/Steps/CampaignStepsTranslator';

import {
  ContactUsEN,
  ContactUsPT,
  ContactUsES,
} from '../containers/HelpCenter/ContactUs/ContactUsTranslator';

import {
  HelpMenuPT,
  HelpMenuEN,
  HelpMenuES,
} from '../containers/HelpCenter/HelpMenu/HelpMenuTranslator';

import {
  TradeInsightsEN,
  TradeInsightsES,
  TradeInsightsPT,
} from '@containers/Trade/Insights/InsightsTranslator';
import {
  FeedbackModalPT,
  FeedbackModalEN,
  FeedbackModalES,
} from '../containers/Scope/MediasCenter/pages/Approvals/FeedbackModal/translate';

import {
  SugestedApprovalsModalPT,
  SugestedApprovalsModalEN,
  SugestedApprovalsModalES,
} from '../containers/Scope/MediasCenter/pages/Approvals/SugestedApprovalsModal/Translate';

import { GMV_PT, GMV_ES, GMV_EN } from '../containers/GMV/GMVTranslator';

import { PLANNER_PT, PLANNER_EN, PLANNER_ES } from '../containers/Scope/Planner/PlannerTranslator';

import {
  TradeTrackingEN,
  TradeTrackingES,
  TradeTrackingPT,
} from '@containers/Trade/Tracking/TrackingTranslator';

import {
  BlockUserModalEN,
  BlockUserModalES,
  BlockUserModalPT,
} from '@containers/Settings/UserTable/BlockUserModal/translate';

import {
  UnblockUserModalEN,
  UnblockUserModalES,
  UnblockUserModalPT,
} from '@containers/Settings/UserTable/UnblockUserModal/translate';

import {
  RevokeInviteModalEN,
  RevokeInviteModalES,
  RevokeInviteModalPT,
} from '@containers/Settings/UserTable/RevokeInviteModal/translate';

import {
  EditUserGroupModalEN,
  EditUserGroupModalES,
  EditUserGroupModalPT,
} from '@containers/Settings/UserTable/EditUserGroupModal/translate';

import {
  EditUserCapabilitiesModalEN,
  EditUserCapabilitiesModalES,
  EditUserCapabilitiesModalPT,
} from '@containers/Settings/UserTable/EditUserCapabilitiesModal/translate';

import {
  EditUserRoleModalEN,
  EditUserRoleModalES,
  EditUserRoleModalPT,
} from '@containers/Settings/UserTable/EditUserRoleModal/translate';

import {
  SelectRoleEN,
  SelectRoleES,
  SelectRolePT,
} from '@containers/Settings/UserTable/SelectRole/translate';

import {
  ReleaseNotesModalEN,
  ReleaseNotesModalES,
  ReleaseNotesModalPT,
} from '../containers/Scope/Report/Dashboard/ReleaseNotesModal/ReleaseNotesModalTranslator';

import {
  ReleaseNotesHistoryEN,
  ReleaseNotesHistoryPT,
  ReleaseNotesHistoryES,
} from '../containers/ReleaseNotesHistory/ReleaseNotesTranslator';

import {
  CustomerSatisfactionEN,
  CustomerSatisfactionPT,
  CustomerSatisfactionES,
} from '../components/CustomerSatisfaction/CostomerSatisfactionTranslator';

import {
  ContactBoxEN,
  ContactBoxPT,
  ContactBoxES,
} from '../components/ContactBox/ContactBoxTranslator';

import {
  DropDownSelectPT,
  DropDownSelectEN,
  DropDownSelectES,
} from '../containers/Scope/MediasCenter/pages/Approvals/MediaModalEditor/components/DropDownSelect/translate';
import { DataInfoEN, DataInfoES, DataInfoPT } from '@containers/Trade/DataInfo/DataInfoTranslator';

import {
  CheckCapabilitiesPT,
  CheckCapabilitiesEN,
  CheckCapabilitiesES,
} from '@containers/Settings/UserTable/CheckCapabilities/translate';

import {
  ProfileAndCoverEN,
  ProfileAndCoverPT,
  ProfileAndCoverES,
} from '../containers/Scope/ProfileAndCover/translate';

import {
  CloneCapabilitiesModalPT,
  CloneCapabilitiesModalEN,
  CloneCapabilitiesModalES,
} from '@containers/Settings/UserTable/CloneCapabilitiesModal/translate';

const translationsPT = {
  locale: 'pt-BR',
  ...MetricsPanelPT,
  ...CardLoginPT,
  ...ChooseCompanyPT,
  ...GuestPT,
  ...CreatePasswordPT,
  ...MainMenuPT,
  ...SidebarPT,
  ...TabsPT,
  ...HeaderPT,
  ...MenuProfilePT,
  ...MenuProfileMobilePT,
  ...DateRangePT,
  ...ProfileSettingsPT,
  ...ButtonCalendarPT,
  ...SettingsEasyAccessPT,
  ...ListAllPT,
  ...TablePT,
  ...TableBudgetAccountsPT,
  ...OverallPT,
  ...OverviewPT,
  ...RankingPT,
  ...AudiencePT,
  ...EngagementPT,
  ...VideoViewPT,
  ...CommentsPT,
  ...ActivityPT,
  ...UserPT,
  ...ImageDropZonePT,
  ...ContentActionsPT,
  ...MenuNotificationPT,
  ...BreadCrumbPT,
  ...FeedPT,
  ...MediasPT,
  ...ManageGroupsPT,
  ...DashboardPT,
  ...ErrorPagePT,
  ...UnitsPT,
  ...UserTablePT,
  ...GroupsPT,
  ...NewDashboardPT,
  ...OrganicPostsPT,
  ...PaidPerformancePT,
  ...FollowUpPT,
  ...HomeBudgetPT,
  ...CompanyPT,
  ...CustomPT,
  ...MetricsPT,
  ...ModalMetricsTablePT,
  ...OnboardingPT,
  ...LineChartsPT,
  ...TableMetricsCustomPT,
  ...WeekReportPT,
  ...MixedChartsPT,
  ...CardCampaignPT,
  ...HelpCenterPT,
  ...ContactUsPT,
  ...HelpMenuPT,
  ...GDScorePT,
  ...HeatMapPT,
  ...CampaignStepsPT,
  ...TradeInsightsPT,
  ...TradeTrackingPT,
  ...FeedbackModalPT,
  ...SugestedApprovalsModalPT,
  ...GMV_PT,
  ...PLANNER_PT,
  ...BlockUserModalPT,
  ...UnblockUserModalPT,
  ...RevokeInviteModalPT,
  ...EditUserGroupModalPT,
  ...EditUserCapabilitiesModalPT,
  ...EditUserRoleModalPT,
  ...SelectRolePT,
  ...ReleaseNotesModalPT,
  ...ReleaseNotesHistoryPT,
  ...CustomerSatisfactionPT,
  ...ContactBoxPT,
  ...DropDownSelectPT,
  ...DataInfoPT,
  ...CheckCapabilitiesPT,
  ...CloneCapabilitiesModalPT,
  ...ProfileAndCoverPT,
};

const translationsEN = {
  locale: 'en-US',
  ...MetricsPanelEN,
  ...CardLoginEN,
  ...ChooseCompanyEN,
  ...GuestEN,
  ...CreatePasswordEN,
  ...MainMenuEN,
  ...SidebarEN,
  ...SettingsEasyAccessEN,
  ...TabsEN,
  ...HeaderEN,
  ...MenuProfileEN,
  ...MenuProfileMobileEN,
  ...DateRangeEN,
  ...ProfileSettingsEN,
  ...ButtonCalendarEN,
  ...ListAllEN,
  ...TableEN,
  ...TableBudgetAccountsEN,
  ...OverallEN,
  ...OverviewEN,
  ...RankingEN,
  ...AudienceEN,
  ...EngagementEN,
  ...VideoViewEN,
  ...CommentsEN,
  ...ActivityEN,
  ...UserEN,
  ...ImageDropZoneEN,
  ...ContentActionsEN,
  ...MenuNotificationEN,
  ...BreadCrumbEN,
  ...FeedEN,
  ...MediasEN,
  ...ManageGroupsEN,
  ...DashboardEN,
  ...ErrorPageEN,
  ...UnitsEN,
  ...UserTableEN,
  ...GroupsEN,
  ...NewDashboardEN,
  ...OrganicPostsEN,
  ...PaidPerformanceEN,
  ...FollowUpEN,
  ...HomeBudgetEN,
  ...CompanyEN,
  ...CustomEN,
  ...MetricsEN,
  ...ModalMetricsTableEN,
  ...OnboardingEN,
  ...LineChartsEN,
  ...TableMetricsCustomEN,
  ...WeekReportEN,
  ...MixedChartsEN,
  ...CardCampaignEN,
  ...HelpCenterEN,
  ...ContactUsEN,
  ...HelpMenuEN,
  ...GDScoreEN,
  ...HeatMapEN,
  ...CampaignStepsEN,
  ...TradeInsightsEN,
  ...TradeTrackingEN,
  ...FeedbackModalEN,
  ...SugestedApprovalsModalEN,
  ...GMV_EN,
  ...PLANNER_EN,
  ...BlockUserModalEN,
  ...UnblockUserModalEN,
  ...RevokeInviteModalEN,
  ...EditUserGroupModalEN,
  ...EditUserCapabilitiesModalEN,
  ...EditUserRoleModalEN,
  ...SelectRoleEN,
  ...ReleaseNotesModalEN,
  ...ReleaseNotesHistoryEN,
  ...CustomerSatisfactionEN,
  ...ContactBoxEN,
  ...DropDownSelectEN,
  ...CheckCapabilitiesEN,
  ...DataInfoEN,
  ...CloneCapabilitiesModalEN,
  ...ProfileAndCoverEN,
};

const translationsES = {
  locale: 'es-ES',
  ...ProfileSettingsES,
  ...CardLoginES,
  ...MenuProfileES,
  ...FeedES,
  ...MainMenuES,
  ...HomeBudgetES,
  ...PaidPerformanceES,
  ...OrganicPostsES,
  ...NewDashboardES,
  ...FollowUpES,
  ...OverviewES,
  ...ListAllES,
  ...TableES,
  ...VideoViewES,
  ...EngagementES,
  ...CommentsES,
  ...ActivityES,
  ...RankingES,
  ...AudienceES,
  ...CustomES,
  ...LineChartsES,
  ...TableMetricsCustomES,
  ...SidebarES,
  ...TabsES,
  ...MediasES,
  ...TabsES,
  ...MetricsPanelES,
  ...CardCampaignES,
  ...ContentActionsES,
  ...ImageDropZoneES,
  ...SidebarES,
  ...UserES,
  ...OverallES,
  ...TableBudgetAccountsES,
  ...ManageGroupsES,
  ...ButtonCalendarES,
  ...DateRangeES,
  ...HelpCenterES,
  ...ContactUsES,
  ...HelpMenuES,
  ...GDScoreES,
  ...HeatMapES,
  ...CampaignStepsES,
  ...TradeInsightsES,
  ...FeedbackModalES,
  ...DashboardES,
  ...ErrorPageES,
  ...TradeTrackingES,
  ...SugestedApprovalsModalES,
  ...UnitsES,
  ...GMV_ES,
  ...PLANNER_ES,
  ...BlockUserModalES,
  ...UnblockUserModalES,
  ...RevokeInviteModalES,
  ...EditUserGroupModalES,
  ...EditUserCapabilitiesModalES,
  ...EditUserRoleModalES,
  ...SelectRoleES,
  ...ReleaseNotesModalES,
  ...ReleaseNotesHistoryES,
  ...CustomerSatisfactionES,
  ...ContactBoxES,
  ...DropDownSelectES,
  ...UserTableES,
  ...CheckCapabilitiesES,
  ...CompanyES,
  ...DataInfoES,
  ...CloneCapabilitiesModalES,
  ...ProfileAndCoverES,
  ...MenuNotificationES,
  ...MenuProfileMobileES,
  ...BreadCrumbES,
  ...HeaderES,
};

export { translationsPT, translationsEN, translationsES };
